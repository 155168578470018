.slide-up-swap-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.slide-up-swap-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.slide-up-swap-exit {
  opacity: 1;
  transform: translateY(0%);
}
.slide-up-swap-exit-active {
  opacity: 0;
  transform: translateY(100%);
}
.slide-up-swap-enter-active,
.slide-up-swap-exit-active {
  transition: opacity 500ms, transform 500ms;
}