.goal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
  pointer-events: none;
  z-index: 9999;
}

.goal-content {
  opacity: 0;
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
}

.animation-option-fade-zoom .scoreboard-content {
  animation: fadeOutAndIn 12s ease-in-out;
}

.animation-option-fade-zoom .goal-content {
  animation: scaleAndFadeIn 10s ease-in-out;
}

@keyframes fadeOutAndIn {
  0%   { opacity: 1; }
  30%  { opacity: 1; }
  40%  { opacity: 0; }
  80%  { opacity: 0; }
  95%  { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes fadeInAndOut {
  0%   { opacity: 0; }
  10%  { opacity: 1; }
  70%  { opacity: 1; }
  95%  { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes scaleAndFadeIn {
  0%   { opacity: 0; transform: scale(0); }
  15%  { opacity: 0; transform: scale(0); }
  35%  { opacity: 1; transform: scale(1); }
  75%  { opacity: 1; transform: scale(1); }
  90%  { opacity: 0; transform: scale(0); }
  100% { opacity: 0; transform: scale(0); }
}

.animation-option-swing .scoreboard-content {
  transform-origin: bottom left;
  animation: swingOut 12s ease-in-out;
}

.animation-option-swing .goal-container {
  transform-origin: bottom left;
  animation: swingIn 10s ease-in-out;
}

.animation-option-swing .goal-content {
  opacity: 1;
}

@keyframes swingOut {
  0%   { transform: rotate(0deg); }
  25%  { transform: rotate(0deg); }
  33%  { transform: rotate(90deg); }
  76%  { transform: rotate(90deg); }
  95%  { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes swingIn {
  0%   { transform: rotate(-90deg); }
  10%  { transform: rotate(-90deg); }
  20%  { transform: rotate(0deg); }
  70%  { transform: rotate(0deg); }
  95%  { transform: rotate(-90deg); }
  100% { transform: rotate(-90deg); }
}

.animation-option-spin .scoreboard-content {
  animation: spinOut 12s cubic-bezier(0.3, -0.2, 0.1, 1.1);
}

.animation-option-spin .goal-content {
  animation: spinIn 10s cubic-bezier(0.3, -0.2, 0.1, 1.3);
}

@keyframes spinOut {
  0%   { opacity: 1; transform: rotate(0deg); }
  15%  { opacity: 1; transform: rotate(0deg); }
  30%  { opacity: 0; transform: rotate(1800deg); }
  60%  { opacity: 0; transform: rotate(1800deg); }
  95%  { opacity: 1; transform: rotate(0deg); }
}

@keyframes spinIn {
  0%   { opacity: 0; transform: rotate(0deg); }
  20%  { opacity: 1; transform: rotate(1800deg); }
  50%  { opacity: 1; transform: rotate(1800deg); }
  95%  { opacity: 0; transform: rotate(0deg); }
}
