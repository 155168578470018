.pulsing-text {
  /* color: white !important; */
  animation: pulse-text-pointpost 2s infinite;
}

.pulsing-circle {
  /* color: white !important; */
  animation: pulse-box-pointpost 2s infinite;
  border-radius: 200px;
}

@keyframes pulse-box-white {
  from {
    box-shadow: 0 0 0.5rem 0.1rem #ffffff66;
	}
	to {
		box-shadow: 0 0 0 0.1rem transparent;
	}
}

@keyframes pulse-box-pointpost {
  from {
    box-shadow:
      0 0 0.5rem 0.1rem #859c77,
      0 0 1rem 0.1rem #859c77;
	}
	to {
		box-shadow: 0 0 0 0.1rem transparent;
	}
}

@keyframes pulse-text-white {
  from {
    color: black;
    text-shadow:
      0 0 0.5rem white,
      0 0 1rem white,
      0 0 2rem white;
	}
	to {
    color: white;
		text-shadow: 0 0 0.1rem transparent;
	}
}

@keyframes pulse-text-pointpost {
  from {
    text-shadow:
      1px 0 0 black,  /* right */
      0 1px 0 black,  /* top */
      -1px 0 0 black, /* left */
      0 -1px 0 black, /* bottom */  
      0 0 2px black,
      0 0 2px black,
      0 0 0.5rem #859c77,
      0 0 0.5rem #859c77,
      0 0 1rem #859c77,
      0 0 1rem #859c77;
	}
	to {
		text-shadow:
    1px 0 0 black,  /* right */
    0 1px 0 black,  /* top */
    -1px 0 0 black, /* left */
    0 -1px 0 black, /* bottom */
    0 0 2px black,
    0 0 2px black;
	}
}