.feature-image-preloader {
  position: absolute;
  top: 150%;
  left: 0;
  height: 10px;
  width: 10px;
}

.goal-feature-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeInAndOut 10s ease-in-out;
}

.goal-feature-text {
  position: absolute;
  top: 20%;
  text-align: right;
  text-transform: lowercase;
  animation: slideInOutFromRight 11s linear;
  color: #000 !important;
}

@keyframes slideInOutFromRight {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }
  10% {
    transform: translateX(50%);
    opacity: 1;
    transition-timing-function: ease-out;
  }
  50% {
    transform: translateX(50%);
    opacity: 1;
  }
  80% {
    transform: translateX(150%);
    opacity: 0;
    transition-timing-function: ease-in;
  }
  100% {
    transform: translateX(150%);
    opacity: 0;
  }
}

